import { useEffect, useRef, useState } from "react";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Select,
  Tooltip,
} from "@mui/material";
import MDButton from "components/MDButton";
import Moment from "moment";
import MUIDataTable from "mui-datatables";
import { MenuItem } from "@material-ui/core";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

/* eslint-disable */
function Details(props) {
  const { openModal, closeModal } = props;
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState("true");
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [senders, setSenders] = useState([]);
  const [selectedSender, setSelectedSenders] = useState(0);
  const [emailCopy, setEmailCopy] = useState();
  const [notes, setNotes] = useState("");

  const [previewTemplate, setPreviewTemplate] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");

  const [previewPrevious, setPreviewPrevious] = useState("");
  const [subjectPrevious, setSubjectPrevious] = useState("");

  const [totalRecipients, setTotalRecipients] = useState(0);
  const [totalSent, setTotalSent] = useState(0);
  const [totalOpen, setTotalOpen] = useState(0);
  const [totalClick, setTotalClick] = useState(0);
  const [totalUnsubscribes, setTotalUnsubscribes] = useState(0);
  const [totalComplaints, setTotalComplaints] = useState(0);

  const [openError, setOpenError] = useState(false);
  const closeError = () => setOpenError(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const closeSuccess = () => setOpenSuccess(false);
  const iframeRef = useRef(null);
  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = () => {
    setIsLoading(false);
    props.closeModal();
  };

  useEffect(() => {
    if (props.broadcast != undefined) {
      setIsLoading(true);
      getSenders();
      getBroadcasts();
      setNotes(props.broadcast.bro_notes);
      getEmailCopy(props.broadcast.bro_emc_id);
    }
  }, [props.broadcast]);

  useEffect(() => {
    if (emailCopy != undefined) {
      if (props.broadcast.bro_emc_id == emailCopy.emc_id) preview();
      else hanldePreviewPrevious();
    }
  }, [emailCopy]);

  useEffect(() => {
    if (props.broadcast != undefined && selectedSender != props.broadcast.bro_ema_id) {
      changeSender();
    }
  }, [selectedSender]);

  useEffect(() => {
    setSelectedSenders(props.broadcast.bro_ema_id);
  }, [senders]);

  useEffect(() => {
    if (iframeRef.current != null) iframeRef.current.scrollIntoView({ behavior: "smooth" });
  }, [previewPrevious]);

  useEffect(() => {
    if (window.innerWidth < 1200) setMargin(0);
    else setMargin(300);
    //calculateRecipients();
  }, [window.innerWidth]);

  function handleTableChange(action, tableState) {
    //  console.log("handleTableChange:... ", tableState.displayData);
    calculateTotals(tableState.displayData);
  }

  const calculateTotals = (data) => {
    const totalRecipients = data.map((a) => a.data[2]).reduce((a, b) => (a += b), 0);
    setTotalRecipients(totalRecipients);
    const totalSent = data.map((a) => a.data[3]).reduce((a, b) => (a += b), 0);
    setTotalSent(totalSent);
    const totalOpen = data.map((a) => a.data[5]).reduce((a, b) => (a += b), 0);
    setTotalOpen(totalOpen);
    const totalClick = data.map((a) => a.data[6]).reduce((a, b) => (a += b), 0);
    setTotalClick(totalClick);
    const totalUnsubscribes = data.map((a) => a.data[9]).reduce((a, b) => (a += b), 0);
    setTotalUnsubscribes(totalUnsubscribes);
    const totalComplaints = data.map((a) => a.data[11]).reduce((a, b) => (a += b), 0);
    setTotalComplaints(totalComplaints);
  };

  const options = {
    method: "GET",
    url: "broadcasts",
  };

  const getSenders = async () => {
    options.method = "GET";
    options.url = `emailSenders`;
    await client
      .request(options)
      .then((response) => {
        setSenders(response);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getBroadcasts = async () => {
    options.method = "GET";
    options.url = `broadcasts/GetByUser?user_id=${props.broadcast.bro_user_id}`;
    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getEmailCopy = async (emc_id) => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `emailCopy/getById?emc_id=${emc_id}`;
    options.params = {
      emc_ser_id: props.serId,
    };

    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setEmailCopy(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const preview = () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailCopy/AdminPreview?user_id=${props.broadcast.bro_user_id}`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      subject: emailCopy.emc_subject,
      body: emailCopy.emc_contentHTML,
    });

    client
      .request(options)
      .then((response) => {
        setSubjectPreview(response.subject);
        setPreviewTemplate(response.body);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const hanldePreviewPrevious = () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailCopy/AdminPreview?user_id=${props.broadcast.bro_user_id}`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      subject: emailCopy.emc_subject,
      body: emailCopy.emc_contentHTML,
    });

    client
      .request(options)
      .then((response) => {
        setSubjectPrevious(response.subject);
        setPreviewPrevious(response.body);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const approve = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/approve`;
    props.broadcast.bro_notes = notes;
    options.data = JSON.stringify(props.broadcast);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setOpenSuccess(true);
        setIsLoading(false);
        setIsLoading(false);
        props.closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const reject = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/reject`;
    props.broadcast.bro_notes = notes;
    options.data = JSON.stringify(props.broadcast);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setOpenError(true);
        setIsLoading(false);
        setIsLoading(false);
        props.closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const changeSender = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/ChangeSender?bro_id=${props.broadcast.bro_id}`;
    options.data = JSON.stringify(senders.find((x) => x.ema_id == selectedSender));
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        props.broadcast.bro_ema_id = selectedSender;
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getDateValue = (date) => {
    return Moment(date).format("L");
  };

  const getStatus = (bro) => {
    switch (bro.bro_status) {
      case 0:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="secondary">
            Pending
          </MDTypography>
        );
      case 1:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="info">
            Sending
          </MDTypography>
        );
      case 2:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="success">
            Sent
          </MDTypography>
        );
      case 3:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="error">
            Failed
          </MDTypography>
        );
      case 4:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="warning">
            Paused
          </MDTypography>
        );
      case 5:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="error">
            Canceled
          </MDTypography>
        );
      case 11:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="info">
            Creating
          </MDTypography>
        );
      case 12:
        if (bro.bro_totalSent > 0)
          return (
            <MDTypography variant="subtitle2" fontWeight="medium" color="info">
              Sending
            </MDTypography>
          );
        else
          return (
            <MDTypography variant="subtitle2" fontWeight="medium" color="info">
              Building
            </MDTypography>
          );
      case 13:
        if (bro.bro_totalSent > 0)
          return (
            <MDTypography variant="subtitle2" fontWeight="medium" color="info">
              Sending
            </MDTypography>
          );
        else
          return (
            <MDTypography variant="subtitle2" fontWeight="medium" color="info">
              Ready
            </MDTypography>
          );
      case 14:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="warning">
            Approval pending
          </MDTypography>
        );
      case 15:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="error">
            Rejected
          </MDTypography>
        );
    }
  };

  const columns = [
    {
      label: "Broadcast name",
      name: "bro_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Sender name",
      name: "ema_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },

    {
      label: "Recipients",
      name: "total_recipients",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Sent",
      name: "bro_totalSent",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Sent %",
      name: "bro_totalSentPerc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Opens",
      name: "total_opens",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Clicks",
      name: "total_clicks",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Opens %",
      name: "total_opens_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Clicks %",
      name: "total_clicks_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Unsubscribes",
      name: "total_unsubscribes",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Unsubscribes %",
      name: "total_unsubs_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Complaints",
      name: "total_complaints",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Complaints %",
      name: "total_complaints_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Sales",
      name: "total_sales",
      options: {
        display: false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => "$ " + Math.round(value),
      },
    },
    {
      label: "Scheduled date",
      name: "bro_date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => getDateValue(value),
      },
    },
  ];

  // const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const changePercentageHandler = (event) => {
    window.sessionStorage.setItem("broadcast_percentage", event.target.value);
    setPercentage(event.target.value);
  };
  const CustomToolbar = ({ displayData }) => {
    return (
      <Tooltip title="Change the stats view between counts and percentage" placement="top">
        <Select
          style={{ marginLeft: 15 }}
          value={percentage}
          label="Change the view of opens and clicks"
          onChange={(event) => changePercentageHandler(event)}
        >
          <MenuItem value="false">#</MenuItem>
          <MenuItem value="true">%</MenuItem>
        </Select>
      </Tooltip>
    );
  };

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "you don't have any broadcast",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "broadcast/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "broadcasts.csv", separator: "," },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchOpen: true,
    searchPlaceholder: "Search",
    customToolbar: CustomToolbar,
    customToolbarSelect: (selectedRows) => {},
    onTableChange: handleTableChange,
    onTableInit: handleTableChange,
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
    onRowClick: (rowData, index) => {
      getEmailCopy(acconuntsData[index.dataIndex].bro_emc_id);
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      getEmailCopy(acconuntsData[rowsSelected[0]].bro_emc_id);
    },
  };

  return (
    <Dialog
      open={openModal}
      onClose={clearVariables}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: "#F4F0F7FF",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        bgColor="primary"
        variant="gradient"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Details for broadcast {props.broadcast != undefined ? props.broadcast.bro_name : ""}
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              {previewTemplate != "" && (
                <MDBox mb={2}>
                  <Grid container mt={5}>
                    <Grid item mb={2} xs={12}>
                      <MDTypography variant="h6" fontWeight="medium" mt={1}>
                        Subject
                      </MDTypography>
                      <iframe
                        srcDoc={subjectPreview}
                        height="100"
                        width="100%"
                        allowFullScreen
                        style={{ border: "none" }}
                      />
                    </Grid>
                    <Grid item mb={2} xs={12}>
                      <MDTypography variant="h6" fontWeight="medium" mt={1}>
                        Body
                      </MDTypography>
                      <iframe
                        srcDoc={previewTemplate}
                        height="600"
                        width="100%"
                        allowFullScreen
                        style={{ border: "none" }}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              )}
              <Grid container mt={5}>
                <Grid item xs={12}>
                  <MDBox mb={5} display="flex" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                      Change sender
                    </MDTypography>
                    <Select
                      disabled={isLoading}
                      onChange={(e) => setSelectedSenders(e.target.value)}
                      value={selectedSender}
                      label="selec a sender to change"
                    >
                      {senders.map((item) => (
                        <MenuItem key={item.ema_id} value={item.ema_id}>
                          {item.ema_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MDInput
                    multiline
                    label="Notes"
                   style={{ width: "50%",marginBottom:10 }}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <MDButton
                    onClick={approve}
                    disabled={isLoading}
                    variant="gradient"
                    color="success"
                    width="100px"
                  >
                    Approve
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                      />
                    )}
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={2}>
                  <MDButton
                    onClick={reject}
                    disabled={isLoading}
                    variant="gradient"
                    color="error"
                    width="100px"
                  >
                    Reject
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                      />
                    )}
                  </MDButton>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Previous broadcasts
                  </MDTypography>
                  <Grid item xs={6} md={12}>
                    <MDBox display="flex" style={{ marginTop: 10 }}>
                      <MDTypography variant="caption1" style={{ marginRight: 10 }}>
                        Total recipients:{totalRecipients}
                      </MDTypography>
                      <MDTypography variant="caption1" style={{ marginRight: 10 }}>
                        Total sent: {totalSent}
                      </MDTypography>
                      <MDTypography variant="caption1" style={{ marginRight: 10 }}>
                        Total open: {totalOpen}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <MDBox display="flex" style={{ marginTop: 10 }}>
                      <MDTypography variant="caption1" style={{ marginRight: 10 }}>
                        Total click: {totalClick}
                      </MDTypography>
                      <MDTypography variant="caption1" style={{ marginRight: 10 }}>
                        Total unsubscribes: {totalUnsubscribes}
                      </MDTypography>
                      <MDTypography variant="caption1" style={{ marginRight: 10 }}>
                        Total complaints: {totalComplaints}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <div style={{ height: "100%", width: "100%" }}>
                    <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
                    {previewPrevious != "" && (
                      <MDBox mb={2}>
                        <Grid container mt={5}>
                          <Grid item mb={2} xs={12}>
                            <MDTypography variant="h6" fontWeight="medium" mt={1}>
                              Subject
                            </MDTypography>
                            <iframe
                              srcDoc={subjectPrevious}
                              height="100"
                              width="100%"
                              allowFullScreen
                              style={{ border: "none" }}
                            />
                          </Grid>
                          <Grid item mb={2} xs={12}>
                            <MDTypography variant="h6" fontWeight="medium" mt={1}>
                              Body
                            </MDTypography>
                            <iframe
                              ref={iframeRef}
                              srcDoc={previewPrevious}
                              height="600"
                              width="100%"
                              allowFullScreen
                              style={{ border: "none" }}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Close
        </MDButton>
      </DialogActions>
      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content={`The broadcast ${
          props.broadcast != undefined ? props.broadcast.bro_name : ""
        } has been approved`}
        dateTime={""}
        open={openSuccess}
        onClose={closeSuccess}
        close={closeSuccess}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        content={`The broadcast ${
          props.broadcast != undefined ? props.broadcast.bro_name : ""
        } has been rejected`}
        title={"Rejected"}
        dateTime={""}
        open={openError}
        onClose={closeError}
        close={closeError}
        bgWhite
      />
    </Dialog>
  );
}
export default Details;
